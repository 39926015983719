const size = {
  xs: "400px",
  sm: "590px",
  md: "768px",
  lg: "960px",
  xl: "1200px"
}
const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`
}

const breakpoint = {
  size,
  device,
};

export default breakpoint;
