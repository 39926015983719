import { createGlobalStyle } from 'styled-components';

import GilroyBold from '../assets/fonts/Gilroy-Bold.woff2';
import GilroyRegular from '../assets/fonts/Gilroy-Regular.woff2';
import GilroyRegularItalic from '../assets/fonts/Gilroy-RegularItalic.woff2';
import GilroySemiBold from '../assets/fonts/Gilroy-SemiBold.woff2';
import breakpoint from "./Breakpoints";

const Typography = createGlobalStyle`
  @font-face {
    font-family: Gilroy;
    font-display: swap;
    font-weight: 700;
    src: url(${GilroyBold});
  }
  @font-face {
    font-family: Gilroy;
    font-display: swap;
    font-weight: 400;
    src: url(${GilroyRegular});
  }
  @font-face {
    font-family: Gilroy;
    font-display: swap;
    font-weight: 400;
    font-style: italic;
    src: url(${GilroyRegularItalic});
  }
  @font-face {
    font-family: Gilroy;
    font-display: swap;
    font-weight: 600;
    src: url(${GilroySemiBold});
  }
  html {
    font-family: Gilroy, Futura, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 10px;
    color: var(--text);
  }
  body {
    font-size: 1.8rem;
    line-height: 2;
    min-width: 320px;
  }
  h1,h2,h3,h4,h5,h6 {
    color: var(--text);
    font-weight: 700;
    line-height: 1.75;
    margin: 0;
  }
  h1,
  .as-h1 {
    font-size: 4rem;
    letter-spacing: -0.02em;
  }
  h2,
  .as-h2 {
    font-size: 3rem;
    letter-spacing: 0.015em;
  }
  h3,
  .as-h3 {
    font-size: 2.4rem;
    letter-spacing: 0.015em;
  }
  h4,
  .as-h4 {
    font-size: 1.8rem;
    letter-spacing: 0.015em;
  }
  h5,
  .as-h5 {
    font-size: 1.6rem;
    letter-spacing: -0.02em;
  }
  h6,
  .as-h6 {
    font-size: 1.4rem;
    letter-spacing: -0.02em;
  }
  a {
    color: var(--primary-light);
    display: inline-block;
    outline: none;
    position: relative;
    text-decoration: underline;

    &::after {
      display: none;
      border: 1px dashed var(--primary);
      bottom: -0.2rem;
      content: '';
      left: -0.2rem;
      position: absolute;
      right: -0.2rem;
      top: -0.2rem;
    }
    
    &:hover {
      color: var(--primary);
    }
    
    &:focus {
      outline: none;
      &::after {
        display: block;
      }
    }
    &:focus:active {
      outline: none;
      &::after {
        display: none;
      }
    }
    &:active {
      color: var(--primary-light);
    }
  }
  p{
    font-size: 1.6rem;
    line-height: 2.8rem;
    
    margin: 0 0 var(--component-margin);
    
    &.is-small{
      font-size: 16px;
      line-height: 28px;
    }
  }

  blockquote {
    position: relative;
    font-size: 3rem;
    line-height: 4.8rem;
    margin: 1.2rem;
    font-weight: 600;

    &::before {
      content: "\\201C";
      font-family: Georgia;
      font-size: 7.2rem;
      line-height: 3.2rem;
      position: absolute;
      left: -4.2rem;
      top: 0;
    }

    &::after {
      content: "\\201D";
      display: inline-block;
      font-family: Georgia;
      font-size: 7.2rem;
      line-height: 3.6rem;
      vertical-align: bottom;
    }
  }
  
  small {
    font-size: 1.6rem;
  }

  .center {
    text-align: center;
  }
  @media only screen and ${breakpoint.device.md}{
    h1,
    .as-h1 {
      font-size: 4.8rem;
    }
    h2,
    .as-h2 {
      font-size: 3.6rem;
    }
    h3,
    .as-h3 {
      font-size: 3rem;
    }
    h4,
    .as-h4 {
      font-size: 2.4rem;
    }
    h5,
    .as-h5 {
      font-size: 1.8rem;
    }
    h6,
    .as-h6 {
      font-size: 1.6rem;
    }
    p {
      font-size: 1.8rem;
      line-height: 3.2rem;
    }
    blockquote {
      font-size: 3.6rem;
      line-height: 4.8rem;
      margin: 2.4rem;
    }
  }
`;

export default Typography;
