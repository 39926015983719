import React from 'react';
import 'normalize.css';
import styled from 'styled-components';

import Header from "./Header";
import Footer from "./Footer";

import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";
import CallToAction from "./CTA";

const MainStyles = styled.main`
  background-color: var(--white);
  //padding: 6rem 2.4rem;
`
const FootStyles = styled.section`
  padding: 1.2rem 2.4rem;
  font-size: 1.2rem;
  text-align: center;
`

const Layout = ({ path, children, ...props }) => {
  const date = new Date();
  let year = date.getFullYear();
  return (
    <>
      <GlobalStyles/>
      <Typography/>
      <a className="skip-main sr-only sr-only-focusable" href="#main-content">Skip to main content</a>
      <Header title={path} key={path} />
      <MainStyles id="main-content">{children}</MainStyles>
      {path !== '/contact/' && <CallToAction isPrimary={path === '/'}/>}
      <Footer />
      <FootStyles>
        <div className="container">&copy; MediaAdvice {year}</div>
      </FootStyles>
    </>
  )
}

export default Layout;
