import React from 'react';
import styled from 'styled-components';
import {Link} from "gatsby";
import PropTypes from "prop-types";
import breakpoint from "../styles/Breakpoints";

const CallToActionStyles = styled.section`
  background-color: var(--secondary);
  color: var(--white);
  text-align: center;

  .container {
    padding: 6rem 2.4rem;
  }
  
  span {
    font-size: 1.6rem;
    line-height: 1.8rem;
    text-transform: uppercase;
  }

  h2 {
    color: var(--white);
    margin: 0.8rem 0 var(--component-margin);
  }
  
  .button {
    margin-top: var(--component-margin);
    margin-bottom: 0;
    
    &:hover {
      background-color: var(--primary);
    }
    &:active {
      color: var(--secondary)
    }
  }

  &.cta_wrap--primary {
    background-color: var(--primary);

    .button {
      &:hover {
        background-color: var(--secondary);
      }
      &:active {
        color: var(--primary-light)
      }
    }
  }

  @media only screen and (max-width:${breakpoint.size.sm}){
    h2 {
      font-size: 3rem;
    }
  }

  @media only screen and ${breakpoint.device.md} {
    .container {
      padding: 8.2rem 2.4rem;
    }
  }
`;

const CallToAction = ({isPrimary}) => {
  return (
    <CallToActionStyles className={`cta_wrap${isPrimary && ' cta_wrap--primary'}`}>
      <div className="container">
        <span>Contact</span>
        <h2>Benieuwd naar wat we voor je kunnen doen?</h2>
        <p>Als full service bureau kunnen we jouw organisatie ondersteunen bij alle communicatie-uitingen.  Interesse? Neem dan contact met ons op via onderstaande button.</p>
        <Link className="button button--white" to="/contact">Contact</Link>
      </div>
    </CallToActionStyles>
  )
}


CallToAction.defaultProps = {
  isPrimary: false
}
CallToAction.propTypes = {
  isPrimary: PropTypes.bool
}

export default CallToAction;
