import React, {useCallback, useEffect, useState} from 'react';
import {graphql, Link, useStaticQuery} from "gatsby";
import BackgroundImage from 'gatsby-background-image'
import PropTypes from "prop-types";
import styled from 'styled-components';
import breakpoint from "../styles/Breakpoints";
import Nav from "./Nav";
import Logo from "./Logo";

const HeaderStyles = styled.header`
  background-color: var(--secondary);
  position: relative;
  margin-bottom: 2rem;
  
  .header__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 3.2rem 2.4rem;
  }

  .button_mobile {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 0 0 0 2.4rem;
    min-width: 32px;
    padding: 0.8rem 0.6rem;
    width: 32px;

    svg {
      color: var(--white);
      display: block;
      height: 20px;
      width: 20px;
      
      path {
        fill: var(--white);
      }
    }

    &:hover {
      background-color: var(--white);

      svg {
        color: var(--primary);
        
        path {
          fill: var(--primary);
        }
      }
    }
  }
  
  .nav_wrap {
    background: var(--secondary);
    bottom: 0;
    display: none;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    
    .nav_wrap__header {
      align-items: center;
      color: var(--white);
      display: flex;
      justify-content: space-between;
      padding: 3.2rem 2.4rem;
      
      h3 {
        color: var(--white);
      }
    }
    
    &[aria-hidden="true"] {
      display: block;
    }
    
    .contact_info {
      list-style: none;
      margin: 0 var(--component-margin) var(--component-margin);
      padding: 0;
      
      a {
        color: var(--white);
        display: block;
        font-size: 1.6rem;
        line-height: 2.4rem;
        
        &:hover {
           color: var(--primary-light);
         }
      }
    }
  }
  
  .header__hero {
    color: var(--white);
    padding: 0.2rem 2.4rem;
    text-align: center;
    
    .container {
      padding: 0 2.4rem;
      max-width: 720px;
    }
    
    h1 {
      color: var(--white);
      margin: 12rem 0;
      
      &::first-letter{
        text-transform: uppercase;
      }
    }
    
    .button {
      margin-bottom: 6rem;
      
      &:hover {
        background-color: var(--white);
        color: var(--primary);
      }
    }

    &.header__hero--home {
      text-align: left;
      
      h1 {
        font-size: 3.6rem;
        line-height: 4.8rem;
        margin: 4.8rem 0 2.4rem;
      }
      p {
        margin-bottom: 4.8rem;
      }
    }
    
    &.header__hero--minimal {
      background-color: var(--white);
      color: var(--black);
      padding: 1px;
      text-align: left;
      
      .container {
        max-width: 1180px;
      }
      
      h1 {
        color: var(--seconary);
        margin: 4.8rem 0 0;
      }
    }
  }

  @media only screen and (max-height:${breakpoint.size.xs}){
    .nav_wrap {
      .nav_wrap__header {
        padding: 1.2rem 2.4rem;
      }
      .contact_info {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and ${breakpoint.device.md}{
    margin-bottom: 3rem;

    .header__hero--minimal {
      margin-bottom: -9.6rem;
    }
    
    .header__nav {
      border-bottom: 1px solid rgba(255,255,255,0.15);

      .container {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }

    .header__content {
      padding: 2.4rem;
    }
    
    .header__hero {
      padding: 1.2rem 0;
    }
    
    .button_mobile,
    .contact_info,
    .nav_wrap .nav_wrap__header{
      display: none;
    }
    
    .nav_wrap {
      display: block;
      position: relative;
    }
  }
  @media only screen and ${breakpoint.device.lg}{
    .header__nav {
      .container {
        flex-direction: row;
      }
    }
    .header__hero {
      .button {
        margin-bottom: 12rem;
      }
      
      &.header__hero--home {

        h1 {
          font-size: 4.8rem;
          line-height: 5.6rem;
          margin-top: 12rem;
        }
        p {
          font-size: 1.8rem;
        }
      }
    }
  }
`

const Header = ({title}) => {
  let data = useStaticQuery(
    graphql`
      query {
        default: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        about: file(relativePath: { eq: "over-ons.jpg" }) {
            childImageSharp {
                fluid(quality: 70, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
        pages: allSanityPage(sort: {order: ASC}, filter: {name: {ne: "Home"}}) {
          nodes {
            name
            slug {
              current
            }
          }
        }
      }
    `
  )

  let heroData = data.default.childImageSharp.fluid;
  if(title === '/over-ons') {
    heroData = heroData = data.about.childImageSharp.fluid;
  }

  let imageData = [heroData,
    `linear-gradient(0deg, rgba(81, 80, 103, 0.76), rgba(81, 80, 103, 0.76))`].reverse()

  const [clicked, setClicked] = useState(false);
  const handleToggle = useCallback((e) => {
    if (e && e.target){
      e.target.blur();
    }
    setClicked((prev) => !prev);

    if ((e && e.key === "Escape") || clicked){
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [clicked]);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      handleToggle(event)
    }
  }, [handleToggle]);

  useEffect(() => {
    clicked && document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [clicked, escFunction]);

  let pageTitle;

  if (title === '/') {
    pageTitle = 'Uw verhaal is onze expertise';
  } else if (title) {
    pageTitle = title.endsWith('/') ? title.substring(0, (title.length - 1)) : title;
    pageTitle = pageTitle.startsWith('/') ? pageTitle.substr(1) : pageTitle;
    pageTitle = pageTitle.replace(/-/g, ' ');
    pageTitle = pageTitle.replace(/\//g, ' - ');
  } else {
    pageTitle = '404';
  }

  const level = title.split('/');
  const hideHero = level.length > 3;

  return (
    <HeaderStyles>
      <div className="header__nav">
        <div className="container">
          <div className="header__content">
            <Link title="Home" to="/">
              <Logo className="logo--invert"/>
            </Link>

            <button className="button_mobile" data-action="open" onClick={handleToggle}>
              <svg viewBox="0 0 24 16">
                <path fill="currentColor" d="M24,16H0v-1.7h24V16z M24,8.8H0V7.2h24V8.8z M24,1.7H0V0h24V1.7z"/>
              </svg>
              <span className="sr-only">Menu</span>
            </button>
          </div>

          <div className="nav_wrap" aria-hidden={clicked}>
            <div className="nav_wrap__header">
              <h3>Menu</h3>
              <button className="button_mobile" data-action="close" onClick={handleToggle}>
                <svg viewBox="0 0 16 16">
                  <path d="M9.5,8.1c0,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.2l6.2-6.1c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L8.1,6.4c0,0-0.1,0.1-0.2,0.1
                  s-0.1,0-0.2-0.1l-6.1-6C1.3,0,0.7,0,0.3,0.4s-0.4,1,0,1.4l6.2,6.1c0,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.2l-6.2,6.1
                  c-0.2,0.2-0.3,0.6-0.2,0.9c0.1,0.3,0.4,0.6,0.7,0.7c0.3,0.1,0.7,0,0.9-0.2l6.2-6.1c0,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1l6.2,6.1
                  c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L9.5,8.1z"/>
                </svg>
              </button>
            </div>
            <Nav pages={data.pages.nodes}/>
            <ul className="contact_info">
              <li><a href="tel:0715125101">071 - 512 51 01</a></li>
              <li><a href="mailto:albert.reinders@mediaadvice.nl">albert.reinders@mediaadvice.nl</a></li>
            </ul>
          </div>
        </div>
      </div>
      {!hideHero && <>
        {title !== '/contact' ? <BackgroundImage
          Tag="div"
          className={`header__hero${title === '/' ? ' header__hero--home' : ''}${title === '/over-ons' ? ' header__hero--about' : ''}`}
          fluid={imageData}
          backgroundColor={`#515067`}
        >
          <div className="container">
            {title !== '/contact' && <h1>{pageTitle}</h1>}
            {title === '/' && (
              <>
                <p>Wanneer heeft corporate communicatie daadwerkelijk impact? Door haarfijn aan te voelen welke
                  strategische boodschap uw organisatie wil uitdragen. Door een scherp oog te hebben voor het
                  politiek-maatschappelijke speelveld waarin organisaties opereren. En door die ervaring te verbinden
                  met een creatieve aanpak die het verschil maakt. Dat is de kracht van MediaAdvice.</p>
                <Link to="/over-ons" className="button">Lees meer</Link>
              </>
            )}
          </div>
        </BackgroundImage> : <div className="header__hero header__hero--minimal">
          <div className="container">
            <h1>{pageTitle}</h1>
          </div>
        </div>}
      </>
      }
    </HeaderStyles>
  );
}

Header.defaultProps = {
  title: null,
  hero: null
}
Header.propTypes = {
  title: PropTypes.string,
  hero: PropTypes.object
}

export default Header;


