import React from 'react';
import { Link } from 'gatsby'
import Logo from "./Logo";
import styled from 'styled-components';
import breakpoint from '../styles/Breakpoints'

const FooterStyles = styled.footer`
  max-width: 1180px;
  margin: 0 auto;
  padding: 4.8rem 2.4rem 1.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  
  .logo {
    margin-top: -0.8rem;
  }

  p {
    margin-top: 2.4rem;
  }

  h3 {
    color: var(--secondary);
    line-height: 3.2rem;
    margin: 2.4rem 0 1.2rem;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    
    li {
      margin: 0.8rem 0;
    }
    
    a {
      color: var(--black);
      font-weight: 600;
      text-decoration: none;

      &:hover,
      &[aria-current="page"] {
        text-decoration: underline;
      }
      
    }
    
    &.contact_info {
      a {
        color: var(--primary);
      }
    }
    &.socials {
      display: flex;
      
      a {
        display: flex;
        align-items: center;
        height: 24px;
        justify-content: center;
        width: 24px;
      }
      
      svg {
        height: 12px;
        width: auto;
        
        path {
          fill: #394A64
        }
      }
    }
  }

  @media only screen and ${breakpoint.device.sm}{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.4rem;
    
    h3 {
      margin-top: 0;
    }
    
    div:first-child {
      grid-column-start: span 2;
    }
  }
  @media only screen and ${breakpoint.device.lg}{
    grid-template-columns:50% calc(20% - 4.8rem) 30%;
    padding-top: 9rem;
    
    h3 {
      margin-bottom: 3.2rem;
    }
    
    div:first-child {
      grid-column-start: span 1;
    }
  }
`

const Footer = () => {
  return (
    <FooterStyles>
        <div>
          <Logo/>
          <p>MediaAdvice verzorgt de interne en externe communicatie voor klanten die actief zijn in het politiek-maatschappelijke speelveld: branche- en koepelorganisaties, kennisinstituten, opleidingsfondsen, universiteiten, zorgverzekeraars en gemeenten.</p>
          <ul>
            <li><a href="/algemene-voorwaarden.pdf" target="_blank" title="Bekijk onze algemene voorwaarden">Algemene voorwaarden</a></li>
          </ul>
        </div>
        <div>
          <h3 className="as-h4">Navigatie</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/over-ons">Over ons</Link></li>
            <li><Link to="/producten">Producten</Link></li>
            <li><Link to="/onze-klanten">Onze klanten</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div>
          <h3 className="as-h4">Contact</h3>
          <p>MediaAdvice is gevestigd in Leiden.</p>
          <ul className="contact_info">
            <li><a href="tel:0715125101">071 - 512 51 01</a></li>
            <li><a href="mailto:albert.reinders@mediaadvice.nl">albert.reinders@mediaadvice.nl</a></li>
          </ul>
          <ul className="socials">
            <li>
              <a href="https://www.linkedin.com/in/albert-reinders-93ab574/" target="_blank" rel="noreferrer">
                <svg viewBox="0 0 16 14">
                  <path d="M1.9,3.3L1.9,3.3C0.7,3.3,0,2.6,0,1.7C0,0.7,0.8,0,1.9,0c1.2,0,1.9,0.7,1.9,1.6C3.8,2.6,3.1,3.3,1.9,3.3zM16,14h-3.5V9.1c0-1.3-0.6-2.2-1.8-2.2C9.8,6.9,9.2,7.5,9,8.1C8.9,8.3,8.9,8.5,8.9,8.8l0,0.1V14H5.4c0,0,0-8.7,0-9.5h3.5V6c0.2-0.7,1.3-1.6,3.1-1.6c2.2,0,4,1.4,4,4.3V14z M3.5,4.5H0.4V14h3.1V4.5z"/>
                </svg>
                <span className="sr-only">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/albertreinders" target="_blank" rel="noreferrer">
                <svg viewBox="0 0 16 14">
                  <path d="M16,1.7c-0.6,0.3-1.2,0.5-1.9,0.6c0.7-0.4,1.2-1.1,1.4-2c-0.6,0.4-1.3,0.7-2.1,0.9C12.9,0.4,12,0,11.1,0
                    C9.3,0,7.8,1.6,7.8,3.5c0,0.3,0,0.5,0.1,0.8C5.2,4.2,2.7,2.8,1.1,0.6C0.8,1.2,0.7,1.8,0.7,2.4c0,1.2,0.6,2.3,1.5,2.9
                    c-0.5,0-1-0.2-1.5-0.4v0c0,1.7,1.1,3.1,2.6,3.5C3,8.5,2.7,8.6,2.4,8.6c-0.2,0-0.4,0-0.6-0.1c0.4,1.4,1.6,2.4,3.1,2.5
                    c-1.1,0.9-2.5,1.5-4.1,1.5c-0.3,0-0.5,0-0.8,0c1.5,1,3.2,1.6,5,1.6c6,0,9.3-5.4,9.3-10.1c0-0.2,0-0.3,0-0.5C15,3,15.6,2.4,16,1.7z"
                  />
                </svg>
                <span className="sr-only">Twitter</span>
              </a>
            </li>
          </ul>
        </div>
    </FooterStyles>
  )
}

export default Footer;
