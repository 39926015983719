import React from 'react';
import { Link } from 'gatsby'
import styled from 'styled-components';
import breakpoint from "../styles/Breakpoints";
import PropTypes from "prop-types";

const NavStyles = styled.nav`
  padding: 1.6rem 2.4rem;
  
  ul {
    list-style: none;
    margin: 0 0 3.2rem;
    padding: 0;
    
    li {
      margin: 0.8rem 0;
      
      a {
        color: var(--white);
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3.2rem;
        position: relative;
        text-decoration: none;

        &:hover,
        &[aria-current="page"],
        &.active {
          text-decoration: underline;
        }
        
        &:active {
          color: var(--primary-light);
        }
      }
    }
  }

  @media only screen and (max-height:${breakpoint.size.xs}){
    padding: 0 2.4rem;
    
    ul {
      margin-bottom: 1.2rem;
    }
  }

  @media only screen and ${breakpoint.device.md}{
    padding: 0;
    
    ul {
      display: flex;
      justify-content: center;
      margin-bottom: 0;

      li {
        margin: 2.4rem 1.6rem;

        a {
          font-size: 1.8rem;
          line-height: 2.4rem;
          padding: 1.2rem 0;

          &:before {
            background-color: var(--white);
            bottom: -24px;
            content: '';
            display: none;
            height: 2px;
            left: 0;
            position: absolute;
            width: 100%;
          }
          
          &:hover {
            text-decoration: none;
            
            &:before {
              display: block;
            }
          }
          
          &[aria-current="page"]
          &.active {
            &:before {
              display: block;
            }
          }
        }
        
        &:last-child {
          a {
            background-color: var(--primary);
            color: var(--white);
            padding: 1.2rem 3.2rem;

            &:hover,
            &[aria-current="page"] {
              text-decoration: underline;
              
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and ${breakpoint.device.lg}{
    ul {
      justify-content: end;
    }
  }
`

const Nav = ({pages}) => {
  return (
    <NavStyles>
      <ul>
        {pages.map((page, index) => (
          <li key={index}>
            <Link to={`/${page.slug.current}`} activeClassName="active" partiallyActive={true}>{page.name}</Link>
          </li>
        ))}
      </ul>
    </NavStyles>
  )
}

Nav.defaultProps = {
  pages: null,
}
Nav.propTypes = {
  pages: PropTypes.array,
}

export default Nav;
