import { createGlobalStyle } from 'styled-components';
import breakpoint from "./Breakpoints";

const GlobalStyles = createGlobalStyle`
  :root {
    --primary: #AF1818;
    --primary-light: #E22C2C;
    --secondary: #515067;
    --text: #2D2F31;
    --black: #000;
    --white: #FFF;
    --component-margin: 2.4rem;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  
  .button,
  button {
    background: var(--primary);
    border: 0;
    cursor: pointer;
    color: white;
    display: inline-block;
    font-weight: 700;
    margin-bottom: var(--component-margin);
    outline: none;
    padding: 1.2rem 2.4rem;
    position: relative;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &::after {
      border: 1px dashed var(--white);
      bottom: 0;
      content: '';
      display: none;
      left: 0;
      margin: 0.2rem;
      position: absolute;
      right: 0;
      top: 0;
    }
    
    &:hover {
      background: var(--secondary);
      cursor: pointer;
      color: var(--white);
    }
    &:focus {
      outline: none;
      &::after {
        display: block;
      }
    }
    &:active {
      background: var(--primary-light);
      
      &::after {
        display: none;
      }
    }
    
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.button--white {
      background-color: var(--white);
      color: var(--secondary);
      
      &::after {
        border-color: var(--primary);
      }

      &:hover {
        background-color: var(--secondary);
        color: var(--white);

        &::after {
          border-color: var(--white);
        }
      }
      &:active {
        border-color: var(--primary-light);
        color: var(--primary-light);
      }
    }
    
    &.button--outline {
      background-color: transparent;
      border: 1px solid var(--secondary);
      color: var(--primary);

      &::after {
        border-color: var(--primary);
      }

      &:hover {
        background-color: transparent;
        border-color: var(--primary);
        color: var(--secondary);

        &::after {
          border-color: var(--secondary);
        }
      }

      &:active {
        border-color: var(--primary-light);
        color: var(--primary-light);
      }
    }
    @media only screen and ${breakpoint.device.sm}{
      padding: 1.2rem 4.2rem;
    }
  }

  .back_to {
    color: var(--secondary);
    display: inline-flex;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: var(--primary);
    }

    span {
      margin-left: 0.8rem;
    }
    svg {
      width: 24px;
      padding: 4px;
      transform: rotate(180deg);

      polygon {
        fill: var(--secondary);
      }
    }
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--primary) #EDEDED;
  }
  body::-webkit-scrollbar-track {
    background: #EDEDED;
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--primary) ;
    border-radius: 6px;
    border: 3px solid #EDEDED;
  }

  img {
    display: block;
    height: auto;
    margin-bottom: var(--component-margin);
    max-width: 100%;
    width: 100%;
  }

  .screen-reader-text,
  .sr-only {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .sr-only-focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }

  .skip-main.sr-only-focusable:focus {
    background: var(--primary); 
    color: #fff;
    font-weight: 400;
    left: 50%;
    margin: 0 auto;
    min-width: 180px;
    padding: 8px 16px;
    position: absolute;
    text-align: center;
    top: var(--component-margin);
    transform: translateX(-50%);
    z-index: 500;
    
    &::after {
      border-color: var(--white);
      bottom: 0;
      left: 0;
      margin: 0.2rem;
      right: 0;
      top: 0;
    }
  }
  .container {
    max-width: 1180px;
    margin: 0 auto;
    width: 100%;
  }
  .text-center {
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  .video_wrap {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    margin-bottom: 1.5rem;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .image_grid {
    display: grid;
    grid-gap: 2.4rem;
    margin-bottom: var(--component-margin);

    @media only screen and ${breakpoint.device.sm}{
      &.image_grid--two {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
`;

export default GlobalStyles;
